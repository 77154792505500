import { ErrorType } from '../types/errors';

export const ErrorMessages: { [key in ErrorType]: string } = {
  [ErrorType.EXCEEDS_ITEM_LIMIT]: 'You have exceeded your item limit',
  [ErrorType.EXCEEDS_STORAGE_LIMIT]: 'You have exceeded your storage limit',
  [ErrorType.FEATURE_NOT_AVAILABLE]: 'You do not have access to this feature',
  [ErrorType.GOOGLE_DRIVE_TOO_MANY_MIRRORS]:
    'You have exceeded the maximum number of Google Drive connections.',
  [ErrorType.GOOGLE_DRIVE_MIRROR_ALREADY_EXISTS]:
    'You already have a Google Drive connection for this drive.',
  [ErrorType.IMPORT_ALREADY_EXISTS]: "You've already imported this file!",
  [ErrorType.WOODY_NOT_READY]: 'Something went wrong. Please try again.',
  [ErrorType.NOT_ENOUGH_CONTENT]: 'Not enough content to generate a summary.',
  [ErrorType.INTEGRATION_AUTHORIZATION_FAILED]:
    'The authorization process failed. Please try again.',
};
