import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import throttled from 'lodash.throttle';
import { useRef } from 'react';

export const useThrottledCallback = <
  T extends (...args: Parameters<T>) => ReturnType<T>,
  F extends T,
>(
  fn: F,
  delay: number = 200,
) => {
  const fnRef = useReferencedFn(fn);
  const throttledFn = useRef(throttled((...args: Parameters<F>) => fnRef.current(...args), delay));
  return throttledFn;
};
