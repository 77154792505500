import { motion } from 'framer-motion';

type IconProps = {
  style?: React.CSSProperties;
  width: string;
  height: string;
  isOpen?: boolean;
};

export const TimelineIcon = ({ style, width, height }: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C6.7136 1.5 4.66616 2.5231 3.29052 4.13646"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M9 4.5V9L12.5 12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export const SpacesIcon = ({ style, width, height }: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="8.75"
      y="0.75"
      width="8.5"
      height="10.5"
      rx="1.25"
      fill="var(--fabric-color-bg-primary)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="4.75"
      y="4.75"
      width="8.5"
      height="10.5"
      rx="1.25"
      fill="var(--fabric-color-bg-primary)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect
      x="0.75"
      y="8.75"
      width="8.5"
      height="10.5"
      rx="1.25"
      fill="var(--fabric-color-bg-primary)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const FolderIcon = ({ style, width, height, isOpen }: IconProps) => {
  return (
    <motion.svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M16.1807 6.10343L16.2018 8.52062C16.2103 9.50109 16.2157 10.1912 16.1763 10.7299C16.1375 11.26 16.0585 11.5765 15.9352 11.822C15.6799 12.3307 15.2693 12.7449 14.7629 13.0046C14.5185 13.13 14.2027 13.2118 13.6729 13.2552C13.1345 13.2994 12.4444 13.2999 11.4639 13.2999H5.488C4.51513 13.2999 3.83059 13.2994 3.29626 13.2557C2.77068 13.2128 2.45681 13.1319 2.21331 13.0078C1.70903 12.7509 1.29904 12.3409 1.0421 11.8366C0.918029 11.5931 0.837182 11.2793 0.79424 10.7537C0.750583 10.2193 0.75 9.53481 0.75 8.56194V5.23523C0.75 4.38294 0.75045 3.78328 0.784186 3.3133C0.817402 2.85055 0.880066 2.57155 0.975717 2.35348C1.24585 1.73763 1.73763 1.24585 2.35348 0.975717C2.57155 0.880066 2.85055 0.817402 3.31329 0.784186C3.78327 0.75045 4.38294 0.75 5.23522 0.75H5.47533C5.70419 0.75 5.78672 0.750343 5.86581 0.755345C6.20035 0.776505 6.52796 0.860241 6.83163 1.00221C6.90342 1.03577 6.97599 1.07507 7.17678 1.18489L7.19705 1.19597C7.37055 1.29088 7.4865 1.3543 7.60644 1.41038C8.08007 1.6318 8.59104 1.76241 9.11284 1.79541C9.24497 1.80377 9.37714 1.80376 9.57488 1.80375L9.598 1.80375H11.8433C12.6193 1.80375 13.1651 1.80412 13.5939 1.83231C14.0163 1.86007 14.2726 1.91249 14.4741 1.99238C15.1537 2.2618 15.6938 2.79716 15.9691 3.4744C16.0508 3.67521 16.1054 3.93105 16.1368 4.35316C16.1688 4.78175 16.1739 5.32753 16.1807 6.10343Z"
        className="transition-all"
        fill="var(--fabric-color-bg-primary)"
        stroke="currentColor"
        strokeWidth="1.5"
        animate={{
          skewX: isOpen ? 12 : 0,
          scale: isOpen ? 0.9 : 1,
        }}
        transition={{
          duration: 0.1,
          ease: [0.075, 0.82, 0.165, 1],
        }}
      />
      <motion.rect
        x="0.75"
        y="4.75"
        width="15.3"
        height="8.62984"
        rx="2.68"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="var(--fabric-color-bg-primary)"
        className="transition-all"
        animate={{
          skewX: isOpen ? -12 : 0,
          scale: isOpen ? 0.9 : 1,
          x: isOpen ? '9%' : 0,
        }}
        transition={{
          duration: 0.1,
          ease: [0.075, 0.82, 0.165, 1],
        }}
      />
    </motion.svg>
  );
};

export const ConnectionsIcon = ({ style, width, height }: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.85874 6.81356H14.4612M2.85874 6.81356V9.00911C2.85874 12.2131 5.45604 14.8104 8.65999 14.8104V14.8104M2.85874 6.81356H1.69849M14.4612 6.81356V9.00911C14.4612 12.2131 11.8639 14.8104 8.65999 14.8104V14.8104M14.4612 6.81356H15.6215M8.65999 14.8104V18.2376M12.1407 6.81356V1.10156M5.17924 6.81356V1.10156"
      stroke="currentColor"
      strokeWidth="1.6065"
      strokeLinecap="round"
    />
  </svg>
);

export const GoogleDriveIcon = ({ style, width, height }: IconProps) => (
  <svg
    viewBox="0 0 87.3 78"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    width={width}
    height={height}
  >
    <path
      d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3L27.5 53H0c0 1.55.4 3.1 1.2 4.5z"
      fill="#0066da"
    ></path>
    <path
      d="M43.65 25 29.9 1.2c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44A9.06 9.06 0 0 0 0 53h27.5z"
      fill="#00ac47"
    ></path>
    <path
      d="M73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75L86.1 57.5c.8-1.4 1.2-2.95 1.2-4.5H59.798l5.852 11.5z"
      fill="#ea4335"
    ></path>
    <path
      d="M43.65 25 57.4 1.2C56.05.4 54.5 0 52.9 0H34.4c-1.6 0-3.15.45-4.5 1.2z"
      fill="#00832d"
    ></path>
    <path
      d="M59.8 53H27.5L13.75 76.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z"
      fill="#2684fc"
    ></path>
    <path
      d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3L43.65 25 59.8 53h27.45c0-1.55-.4-3.1-1.2-4.5z"
      fill="#ffba00"
    ></path>
  </svg>
);

const _NotionIcon = ({ style, width, height }: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.017 4.313 61.35.226c6.797-.583 8.543-.19 12.817 2.917L91.83 15.586c2.913 2.14 3.883 2.723 3.883 5.053v68.243c0 4.277-1.553 6.807-6.99 7.193l-64.256 3.892c-4.08.193-6.023-.39-8.16-3.113L3.3 79.94C.967 76.827 0 74.497 0 71.773v-60.66c0-3.497 1.553-6.413 6.017-6.8z"
      fill="#fff"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.35.227 6.017 4.314C1.553 4.7 0 7.617 0 11.113v60.66c0 2.723.967 5.053 3.3 8.167l13.007 16.913c2.137 2.723 4.08 3.307 8.16 3.113l64.257-3.89c5.433-.387 6.99-2.917 6.99-7.193V20.64c0-2.21-.873-2.847-3.443-4.733L74.167 3.143C69.894.036 68.147-.357 61.35.226zM25.92 19.523c-5.247.353-6.437.433-9.417-1.99l-7.576-6.026c-.77-.78-.383-1.753 1.557-1.947l53.193-3.887c4.467-.39 6.793 1.167 8.54 2.527l9.123 6.61c.39.197 1.36 1.36.193 1.36L26.6 19.477l-.68.047zM19.803 88.3V30.367c0-2.53.777-3.697 3.103-3.893L86 22.78c2.14-.193 3.107 1.167 3.107 3.693V84.02c0 2.53-.39 4.67-3.883 4.863l-60.377 3.5c-3.493.193-5.043-.97-5.043-4.083zm59.6-54.827c.387 1.75 0 3.5-1.75 3.7l-2.91.577v42.773c-2.527 1.36-4.853 2.137-6.797 2.137-3.107 0-3.883-.973-6.21-3.887l-19.03-29.94V77.8l6.02 1.363s0 3.5-4.857 3.5l-13.39.777c-.39-.78 0-2.723 1.357-3.11l3.497-.97v-38.3l-4.853-.393c-.39-1.75.58-4.277 3.3-4.473l14.367-.967 19.8 30.327v-26.83l-5.047-.58c-.39-2.143 1.163-3.7 3.103-3.89l13.4-.78z"
      fill="#000"
    ></path>
  </svg>
);
