import { useThrottledCallback } from '@/src/hooks/useThrottledCallback';
import { ScrollPosition } from '@/src/modules/ui/ui.types';
import React from 'react';

const useScrollPosition = <T extends HTMLElement>(
  refEl?: T | null,
  options?: {
    observeParentResize?: boolean;
  },
) => {
  const [scrollPosition, setScrollPosition] = React.useState<ScrollPosition | null>(null);

  const updateScrollPositionRef = useThrottledCallback(() => {
    if (!refEl) return;

    const atTop = refEl.scrollTop === 0;
    const atBottom = refEl.scrollHeight - refEl.clientHeight === refEl.scrollTop;
    const atLeft = refEl.scrollLeft === 0;
    const atRight = refEl.scrollWidth - refEl.clientWidth === refEl.scrollLeft;
    const hasVerticalScroll = refEl.scrollHeight > refEl.clientHeight;
    const hasHorizontalScroll = refEl.scrollWidth > refEl.clientWidth;

    setScrollPosition({
      x: refEl.scrollLeft,
      y: refEl.scrollTop,
      vertical: hasVerticalScroll
        ? !atTop && !atBottom
          ? 'middle'
          : atTop
            ? 'top'
            : 'bottom'
        : 'n/a',
      horizontal: hasHorizontalScroll
        ? (!atLeft && !atRight) || !hasHorizontalScroll
          ? 'center'
          : atLeft
            ? 'left'
            : 'right'
        : 'n/a',
    });
  }, 100);

  /**
   * update
   */
  React.useEffect(() => {
    const updateScrollPosition = updateScrollPositionRef.current;

    if (refEl) {
      updateScrollPosition();

      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry && refEl) {
          updateScrollPosition();
        }
      });

      resizeObserver.observe(refEl);
      resizeObserver.observe(document.body);
      /**
       * observe parent element as well, maybe it's resizing, changing layout
       * good to observe the positioning of the element withing the window context
       */
      if (options?.observeParentResize && refEl.parentElement) {
        resizeObserver.observe(refEl.parentElement);
      }

      window.addEventListener('scroll', updateScrollPosition);
      refEl.addEventListener('scroll', updateScrollPosition);
      return () => {
        setScrollPosition(null);
        resizeObserver.disconnect();
        window.removeEventListener('scroll', updateScrollPosition);
        refEl.removeEventListener('scroll', updateScrollPosition);
      };
    } else {
      setScrollPosition(null);
    }

    return () => {
      if ('cancel' in updateScrollPosition) updateScrollPosition.cancel();
    };
  }, [refEl, options?.observeParentResize, updateScrollPositionRef]);

  return [scrollPosition, setScrollPosition] as const;
};

export { useScrollPosition };
