import { useQueryResourceRootList } from '@/src/modules/resource-roots/queries/useQueryResourceRootList';
import {
  ResourceRootIntegration,
  ResourceRootListFilter,
} from '@/src/modules/resource-roots/resource-roots.types';
import React from 'react';

export const useQueryResourceRootIntegrationList = (
  queryFilter?: Omit<ResourceRootListFilter, 'type'>,
) => {
  const query = useQueryResourceRootList(queryFilter);

  const integrationRoots: ResourceRootIntegration[] = React.useMemo(() => {
    return query.roots.filter((root) => !!root.integration);
  }, [query.roots]);

  return {
    ...query,
    integrationRoots,
  };
};
