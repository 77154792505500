import { cssVar } from '@/src/modules/ui/theme/variables';
import { cssPropertySizeValue } from '@/src/modules/ui/utils/cssProperties';
import styled, { keyframes } from 'styled-components';

interface SkeletonProps extends React.HTMLAttributes<HTMLSpanElement> {
  width?: number | string;
  height?: number | string;
  circle?: boolean;
  borderRadius?: number;
}

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const Skeleton = styled.span.attrs<SkeletonProps>((props) => ({
  children: (
    <>
      {props.children}
      ‌&zwnj;
    </>
  ),
  ...props,
}))<SkeletonProps>`
  --shimmer-colour-rgb: ${cssVar['color-bg-quarternary-rgb']};

  background: ${cssVar['color-bg-tertiary']};
  color: transparent;
  width: 100%;
  line-height: 1;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border-radius: ${(props) => (props.circle ? '50%' : `${props.borderRadius || 0}px`)};
  width: ${(props) => cssPropertySizeValue(props.width)};
  height: ${(props) => cssPropertySizeValue(props.height)};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(var(--shimmer-colour-rgb), 0) 0,
      rgba(var(--shimmer-colour-rgb), 0.2) 20%,
      rgba(var(--shimmer-colour-rgb), 0.5) 60%,
      rgba(var(--shimmer-colour-rgb), 0)
    );
    animation: ${shimmer} 1s infinite;
    content: '';
  }
`;

export default Skeleton;
